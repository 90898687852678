import React from "react";
import logo from "../images/ecell/logo.png";

const Footer = () => (
  <footer className="section footer" id="footer-dark">
    <div className="container">
      <div className="row">
        <div className="col-md-3 mb-5">
          <div className="footer-widget">
            <img src={logo} alt="CAP"/>
          </div>
        </div>

        <div className="col-md-3 mb-5">
          <div className="footer-widget">
            <h3 className="mb-4">About CEED</h3>
            <p>CEED is an entrepreneurship development endeavor of Chitkara University to develop a mind set of being an
              entrepreneur while a student is in its academic tenure. </p>
          </div>
        </div>

        <div className="col-md-3">
          <div className="footer-widget">
            <h3 className="mb-4">Contact Team E-CELL</h3>
            <p><i className="ion-email"/>&nbsp;<a href={"mailto:ecellcuhp@chitkarauniversity.edu.in"}> Ms. Abha Sharma <br/> (Senior Mentor CEED) </a></p>
            <p><i className="ion-ios-telephone"/>&nbsp;<a href={"tel:+91 6239444935"}>+91 6239444935  <br/> Shraddha Thakur (President E-Cell)</a></p>
          </div>
          <div className="footer-widget">
            <h3 className="mt-5 mb-4">For General Queries</h3>
            <p><a href={"mailto:ecellcuhp@chitkarauniversity.edu.in"}>ecellcuhp@chitkarauniversity.edu.in</a></p>
          </div>
        </div>

        <div className="col-md-3">
          <div className="footer-widget follow">
            <h3 className="mb-4">Follow E-CELL</h3>
            <ul>
              <li><a href="https://www.facebook.com/ecellcuhp/" target="_blank"><i className="ion-social-facebook"/></a></li>
              <li><a href="https://twitter.com/ecellcuhp" target="_blank"><i className="ion-social-twitter"/></a></li>
              <li><a href="https://instagram.com/ecellcuhp?igshid=gumwkarzrnri" target="_blank"><i className="ion-social-instagram"/></a></li>
            </ul>
          </div>
        </div>

      </div>

      <div className="row pt-5 border-top">
        <div className="col-md-12 text-center">
          <p>&copy; Copyright 2019 © <a href={"http://cuceed.org"} target={"_blank"}>E-CELL</a>. All Rights Reserved</p>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
