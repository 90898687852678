import sumeer from "../images/ecell/mentor-sumeer-walia_s.jpg";
import abha from "../images/ecell/mentor-abha-sharma_s.jpg";
import shivam from "../images/ecell/mentor-shivam_s.jpg";
import bhavesh from "../images/ecell/mentor-bhavish_s.jpg";
import anjuman from "../images/ecell/mentor-anjuman-arora.jpeg";
import aakarshan from "../images/ecell/aakarshan.jpeg";
import hussain from "../images/ecell/mentor-aaquib.jpg";
import mausumi from "../images/ecell/mentor-mausumi.jpg";
import avelo from "../images/ecell/mentor-avelo.jpg";
import harit from "../images/ecell/mentor-harit.png";
import ritu from "../images/ecell/mentor-ritu-singal.jpg";
import shiv from "../images/ecell/mentor-shivdeep.jpg";
import vidushi from "../images/ecell/mentor-vidushi.jpg";

const data = [
  {
    "image": sumeer,
    "name": "Sumeer Walia",
    "title": "Director CEED"
  }, {
    "image": abha,
    "name": "Ms. Abha Sharma",
    "title": "Senior Mentor CEED"
  },
  {
    "image": shivam,
    "name": "Shivam Gupta",
    "title": "Cofounder at Volumetree"
  }, {
    "image": bhavesh,
    "name": "Bhavish Sood",
    "title": "Managing partner at BlueSky Capital Management"
  },
  {
    "image": anjuman,
    "name": "Dr. Anjuman Arora Ahuja",
    "title": "Chief Strategy Officer & Head of Business Development – DataToBiz"
  },
  {
    "image": aakarshan,
    "name": "Aakarshan Sethi",
    "title": "Entrepreneurship Expert"
  }, {
    "image": hussain,
    "name": "Aaquib Hussain",
    "title": "Creative Head and Director i&We"
  }, {
    "image": mausumi,
    "name": "Mausumi Acharyya",
    "title": " Founder - Advenio - ChironX"
  }, {
    "image": harit,
    "name": "Harit Mohan",
    "title": "Founder & CEO, Signicent LLP"
  }, {
    "image": vidushi,
    "name": "Vidushi Malhotra",
    "title": "Co-Founder & Chief Strategist, Ingenious Faces"
  }, {
    "image": shiv,
    "name": "Shivdeep Brar",
    "title": "Founder & CEO, Agvisi Technologies Pvt. Ltd."
  }, {
    "image": ritu,
    "name": "Ritu Singal",
    "title": "M.D Raglan Group, Women Entrepreneur of year 2011"
  }, {
    "image": avelo,
    "name": "Avelo Roy",
    "title": "MD & CEO, Kolkata Ventures"
  }
];

export default data;
