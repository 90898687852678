import shraddha from "../images/ecell/team-shraddha-thakur.jpeg";
import saurabh from "../images/ecell/team-saurabh-sharma.jpeg";
import pranav from "../images/ecell/team-pranav-goyal.jpeg";
import nandini from "../images/ecell/team-nandini-shahi.jpeg";
import karan from "../images/ecell/team-karan-telang.jpeg";
import anshul from "../images/ecell/team-anshul-arora.jpeg";

const data = [
  {
    "image": shraddha,
    "name": "Shraddha Thakur",
    "title": "President, E-Cell"
  },
  {
    "image": anshul,
    "name": "Anshul Arora",
    "title": "Vice President, E-Cell"
  },
  {
    "image": saurabh,
    "name": "Saurabh Sharma",
    "title": "Secretary, E-Cell"
  },
  {
    "image": pranav,
    "name": "Pranav Goyal",
    "title": "Executive Member - Intern, E-Cell"
  },
  {
    "image": nandini,
    "name": "Nandini Shahi",
    "title": "Executive Member, E-Cell"
  },
  {
    "image": karan,
    "name": "Karan Telang",
    "title": "Executive Member, E-Cell"
  }
];

export default data;
