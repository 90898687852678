import React from "react";
import Card from "../components/card";
import data from "../data/stories";

const Blog = () => (
  <section id="section-blog-2" className="section">
    <div className="container">
      <div className="row">
        <div className="col-lg-8 m-auto text-center">
          <div className="section-heading">
            <h6 className="subtitle text-primary">SUCCESS STORIES</h6>
            <h2 className="display-4">Stories that Inspire Us</h2>
          </div>
        </div>
      </div>

      <div className="row justify-content-center">
        {
          data.map((story, index) => {
            return (
              <div className="col-lg-6 col-md-6" key={index}>
                <Card image={story.image}
                      title={story.title}
                      description={story.description}/>
              </div>
            );
          })
        }
      </div>
    </div>
  </section>
);

export default Blog;
