import zadd from "../images/ecell/zadd.jpg";
import chaiNagari from "../images/ecell/chaiNagari.jpg";

const data = [
  {
    "image": chaiNagari,
    "title": "Chai Nagri",
    "description": "CEED launched “CHAI NAGRI” a new Start-up at Square One Chitkara University om 1 August 2018!! The start-up is of two founders Divam Wadhwa an alumnus of Chitkara University, Himachal Pradesh Batch 2014 and Simran, childhood friend of Divam! A new business journey of two friends has begun with this venture “CHAI NAGRI”. The start-up was incubated at Chitkara Innovation Incubator for a period of 1 year before the commercial launch for the pilot project!! Currently they have outlets at four locations."
  }, {
    "image": zadd,
    "title": "Zadd - responsive e-bike",
    "description": "Students of Chitkara University Himachal Pradesh launch a responsive e-bike that has an international market, targeting premium customers. Features include Bluetooth enabled, GPS/GSM, Theft proof, Motion sensitive, Heart rate controlled, Crash detection alert, Cloud connectivity, Keyless operation, Automatic. The bike is made of light Carbon fibre frame having 80 km range with 25km/hour speed and 2-hour charging time. The E-Bike was launched on February 20th,2019 in the college premises with a grand celebration."
  }
];

export default data;
