import React from "react";

const Banner = () => (
  <section id="banner" className="banner">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-10 text-center">
          <div className="banner-content">
            <h1 className="lg-title mt-4 mb-5">
              JOINING HANDS IN BUILDING AN ENTERPRISING
              <br/> INDIA
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div className="scroll">
      <a><span></span></a>
    </div>
  </section>
);

export default Banner;
