import startUpWeekend from "../images/ecell/event-startup-weekend.jpg";
import startUpIndiaYatra from "../images/ecell/event-startup-india-yatra.jpg";
import mentorVol1 from "../images/ecell/event-mentor-mentee-vol-1.jpg";
import workshop from "../images/ecell/event-social-entrepreneurship.jpg";
import surge from "../images/ecell/event-surge.jpg";
import sartUpDay from "../images/ecell/event-startup-day.jpg";
import mentorVol2 from "../images/ecell/event-mentor-mentee-vol-2.jpg";
import meridian from "../images/ecell/event-meridian.jpg";
import weekend from "../images/ecell/bootcamp.jpg";

const data = [
  {
    "image": startUpWeekend,
    "title": "START-UP WEEKEND",
    "description": "Startup Weekend Baddi a 3-day overnight event organized by CEED from 21st-23rd September 2018. “Startup Weekend is a 54-hour event that brings together global Startup Powered by Google”."
  }, {
    "image": startUpIndiaYatra,
    "title": "START-UP INDIA YATRA",
    "description": "The government of India’s initiative setup by the honorable PM has been swiftly marching forward in various cities and regions and states of India. Chitkara University as one of the stops for Startup India Yatra Bootcamp, 26 startup ideas were pitched out of which 16 were shortlisted for Grand Finale.  "
  },
  {
    "image": mentorVol1,
    "title": "MENTOR-MENTEE SERIES Vol-1",
    "description": "A new program named mentor-mentee series initiated by CEED. It aims to guide students where external mentor guides them how to focus on the execution of your idea."
  },
  {
    "image": workshop,
    "title": "WORKSHOP ON SOCIAL ENTREPRENEURSHIP",
    "description": "A highly intense and insightful workshop on “Social Entrepreneurship” was conducted by CEED E-cell. The resource person Ms. Shefali Saluja a distinguished assistant professor at Chitkara Business School holds certification in social entrepreneurship."
  },
  {
    "image": surge,
    "title": "SURGE 0.1 ",
    "description": "A five-day gala celebration of entrepreneurship” Chitkara University, Himachal Pradesh hosted SURGE0.1 on 19th and 20th February 2019. The magnificent event saw a participation of over 700 entrepreneurs, budding entrepreneurs, researchers, faculty, staff & students. "
  },
  {
    "image": sartUpDay,
    "title": "START-UP DAY",
    "description": "Start-up Day 2019 is the 3rd intra-college event that witnessed 13 finalists pitching in the finals, those were shortlisted from 28 ideas."
  },
  {
    "image": mentorVol2,
    "title": "MENTOR-MENTEE SERIES Vol-2 ",
    "description": "After the grand success of Mentor-Mentee Vol-1, Volume 2 was initiated that witnessed Mr. Shivam Gupta CEO Azuratech where he portrayed his idea of entrepreneurship as a mindset and philosophy and also explained organizing and managing business venture along with risks."
  },
  {
    "image": meridian,
    "title": "MERIDIAN",
    "description": "A CAP exchange tour to Chitkara University, Himachal Pradesh on 9th August 2019 was organised where CAP entrepreneurs presented their ideas to Vice-Chancellor Dr. Varinder Kanwar. The event witnessed functioning of E-cell of both the campuses and the striking rise of entrepreneurship culture in the region."
  },
  {
    "image": weekend,
    "title": "STARTUP WEEKEND BOOTCAMP",
    "description": "CEED conducted its first bootcamp for Startup Weekend Baddi Women at Audi 108 which was witnessed by an interactive audience of more than 70 students. They were acknowledged about the upcoming mega event \"Startup Weekend\" by putting up a spectacular show with videos and team building activities."
  }
];

export default data;
