import React from "react";
import about from "../images/ecell/about-bg.jpg";
import { Link } from "gatsby";

const About = () => (
  <section id="about" className="section about-us">
    <div className="container">
      <div className="row justify-content-end align-items-center ">
        <div className="col-lg-6 col-md-6">
          <div className="event-img">
            <img src={about} alt="" className="img-fluid img-style"/>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="video-content">
            <h6 className="text-primary text-uppercase mb-3 subtitle">About E-Cell</h6>
            <h2 className="mb-4 display-4">A CHITKARA UNIVERSITY HP INITIATIVE TO BUILD START-UP ECOSYSTEM</h2>
            <p className="mb-5">
              Entrepreneurship cell, Chitkara University Himachal Pradesh aims at fostering the problem-solving
              abilities and spirit of start-up ecosystem by providing the students with a platform which gives them
              number of innovative opportunities to develop the Entrepreneur in them. Our entrepreneurship cell provides
              professional mentorships and other employer opportunities for building an ecosystem for start-ups.
            </p>
            <Link to="/about" className="btn btn-hero btn-rounded">Read More</Link>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default About;
