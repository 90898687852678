import React, { Component } from "react";
import image1 from "../images/ecell/event-startup-india-yatra.jpg";
import image2 from "../images/ecell/event-meridian.jpg";
import image3 from "../images/ecell/event-startup-day.jpg";
import image4 from "../images/ecell/event-social-entrepreneurship.jpg";
import Carousel, { ModalGateway, Modal } from "react-images";

class Gallery extends Component {

  constructor (props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false
    };
  }

  componentDidMount () {

  }

  openLightBox (index) {
    this.setState({
      isOpen: true,
      photoIndex: index
    });

  }

  render () {
    const images = [image1, image2, image3, image4];
    const { photoIndex, isOpen } = this.state;
    return (
      <section id="gallery" className="section gallery">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading">
                {/*<h6 className="subtitle text-primary">CAP COMMUNITY</h6>*/}
                <h6 className="subtitle text-primary">E-CELL GALLERY</h6>
                <h2 className="display-4">Building Startups</h2>
              </div>
            </div>
          </div>
          <div className="images">
            <div className="row gutter">
              {
                images.map((item, index) => {
                  return (
                    <div key={index} className="col-lg-6 column">
                      <a className="image"
                         style={{ backgroundImage: "url(" + images[index] + ")" }}
                         onClick={this.openLightBox.bind(this, index)}/>
                    </div>
                  );
                })
              }
            </div>
          </div>
          <ModalGateway>
            {isOpen ? (
              <Modal onClose={() => this.setState({ isOpen: false })}>
                <Carousel currentIndex={photoIndex} views={images.map((source) => {
                  return {
                    source
                  };
                })}/>
              </Modal>
            ) : null}
          </ModalGateway>
        </div>
      </section>
    );
  }
}

export default Gallery;
